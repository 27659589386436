<template>
  <div class="personal-details">
    <div class="first-row">
      <div class="name">{{ name }}</div>
      <div class="edit-button">
        <SfButton @click="$emit('on-edit-click')" class="sf-button--pure">
          <font-awesome-icon :icon="['fas', 'pencil-alt']" />
        </SfButton>
      </div>
    </div>
    <div class="second-row">
      <div v-if="phoneNumber" class="phoneNumber">
        {{ phoneNumber.substring(3) }}
      </div>
      <div class="dot" v-if="phoneNumber && email">&bull;</div>
      <div v-if="email" class="email">{{ email }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SfButton } from "@lib/components";
export default {
  components: {
    SfButton,
  },
  computed: {
    ...mapGetters({
      name: "user/displayName",
      phoneNumber: "user/phoneNumber",
      email: "user/email",
    }),
  },
};
</script>

<style lang="scss" scoped>
.personal-details {
  margin-top: 1.5rem;
  font-size: var(--font-base);
  font-family: var(--font-family-primary);
  font-weight: 300;

  .first-row {
    display: flex;
    justify-content: space-between;
  }

  .second-row {
    display: flex;
    justify-content: left;
    margin-top: 0.66rem;
    margin-bottom: 1.33rem;

    .dot {
      margin: 0 0.75rem;
    }
  }
}
</style>
