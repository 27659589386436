var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checkout"},[(!_vm.orderComplete)?_c('div',{staticClass:"checkout__wrapper"},[(!_vm.isAppointment)?_c('div',{staticClass:"checkout__main"},[_c('SfSteps',{attrs:{"active":_vm.currentStep,"steps":_vm.steps},on:{"change":_vm.changeStep}},[_c('SfStep',{attrs:{"name":"Choose Mode"}},[_c('CheckoutSelectMode',{on:{"next":function($event){return _vm.nextStep()}}})],1),_c('SfStep',{attrs:{"name":"Enter Details"}},[_c('CheckoutDetailsMode',{on:{"next":function($event){return _vm.nextStep()}}})],1),_c('SfStep',{attrs:{"name":"Place Order"}},[_c('CheckoutReview',{on:{"next":function($event){return _vm.nextStep()},"order-completed":_vm.setOrderCompleted}})],1)],1)],1):_vm._e(),(_vm.isAppointment)?_c('div',{staticClass:"checkout__appointment"},[(_vm.isAppointment)?_c('CheckoutReview',{on:{"order-completed":_vm.setOrderCompleted}}):_vm._e()],1):_vm._e()]):_vm._e(),(
      _vm.orderComplete &&
      (!_vm.providerConfig || _vm.providerConfig.providerName !== 'upi-manual')
    )?_c('OrderSuccess',{staticClass:"order-success",attrs:{"order-id":_vm.orderId,"success-message":_vm.store.orderConfig.messageAfterSuccess || 'Order Placed'}}):_vm._e(),(
      _vm.orderComplete &&
      _vm.providerConfig &&
      _vm.providerConfig.providerName === 'upi-manual'
    )?_c('OrderSuccessUpi',{staticClass:"order-success",attrs:{"order-id":_vm.orderId,"order":_vm.order,"success-message":_vm.store.orderConfig.messageAfterSuccess || 'Order Placed',"upi-config":_vm.providerConfig}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }