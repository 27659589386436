import firebase from "@lib/firebase";
import config from "@config/config";
const isProd = () => {
  return config.environment === "production" || config.environment === "shopman-production";
};

const analytics = isProd() ? firebase.analytics() : {};

const setStoreId = (storeId) => {
  analytics.setUserProperties({ store_id: storeId });
};

const setUserEmail = (email) => {
  analytics.setUserId(email);
};

const logOrderEvent = (storeId, order, status) => {
  const eventParams: any = {
    store_id: storeId,
    order_id: order.id,
    order_type: order.type,
    is_user_loggedin: !order.userInfo,
    order_status: status,
  };
  if (order.personalDetails) {
    if (order.personalDetails.phone) {
      eventParams.phone = `${order.personalDetails.phoneDialCode}${order.personalDetails.phone}`;
    }
    if (order.userInfo) {
      eventParams.userInfo = order.userInfo;
    }
  }
  analytics.logEvent("order_created", eventParams);
};

export default {
  setStoreId: isProd() ? setStoreId : () => {},
  setUserEmail: isProd() ? setUserEmail : () => {},
  logOrderEvent: isProd() ? logOrderEvent : () => {},
};
