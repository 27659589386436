<template>
  <div class="checkout-review">
    <div class="checkout-review__wrapper review-section">
      <SfHeading
        v-if="deliveryMode"
        title="Order review"
        :level="2"
        class="sf-heading--left sf-heading--no-underline title"
      />
      <CartProduct
        v-for="product in productsInCart"
        :key="product.id"
        :product="product"
        :show-stock-errors="showStockErrors"
        :allow-edit="false"
        @click:remove="removeHandler(product)"
      />
      <div class="summary">
        <div class="summary__content">
          <SfHeading
            title="Totals"
            :level="1"
            class="sf-heading--left sf-heading--no-underline summary__title"
          />
          <SfProperty
            name="Items"
            :value="numberOfItemsInCart"
            class="sf-property--full-width property"
          />
          <SfProperty
            v-for="total in totals"
            :key="total.code"
            :class="[
              'sf-property--full-width o-microcart__total-price',
              total.code,
            ]"
          >
            <template #name>
              <span class="sf-property__name">{{ total.title }}</span>
            </template>
            <template #value>
              <SfPrice
                v-if="!total.isFree"
                :regular="total.value | price"
                class="sf-price--big"
              />
              <SfPrice
                v-else
                :regular="total.value | price"
                special="Free"
                class="sf-price--big"
              />
            </template>
          </SfProperty>
        </div>
      </div>
      <div class="actions">
        <SfButton
          v-if="isAppointment"
          class="sf-button--full-width actions__button"
          :disabled="!isFormValid"
          @click="proceedToAppointment"
        >
          Book an appointment
        </SfButton>
        <SfButton
          v-else-if="!paymentsEnabled"
          class="sf-button--full-width actions__button"
          :disabled="!isFormValid"
          @click="placeOrder"
          >Place my order
        </SfButton>
        <div v-else>
          <SfButton
            v-for="provider in paymentProviders"
            :key="provider.id"
            class="sf-button--full-width actions__button"
            :disabled="!isFormValid"
            @click="initiatePayment(provider)"
          >
            {{ provider.buttonText }}
          </SfButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";
import { required, minLength, email } from "vuelidate/lib/validators";
import { ModalList } from "@theme/store/ui/modals";

const upiManualClient = () =>
  import(
    /* webpackChunkName: "upi-manual" */ "@lib/modules/payment/upi-manual/components/client.vue"
  );

const payuClient = () =>
  import(
    /* webpackChunkName: "payu" */ "@lib/modules/payment/payu/components/client.vue"
  );

import {
  SfHeading,
  SfButton,
  SfProperty,
  SfPrice,
  CartProduct,
} from "@lib/components";

import ALoadingSpinner from "@theme/components/atoms/a-loading-spinner";
import ALoadingError from "@theme/components/atoms/a-loading-error";
import CountryCodes from "@lib/utility/countryCodes";
import analytics from "@lib/utility/analytics";
import { getDeviceId } from "@lib/modules/analytics/services";
import config from "@config/config";

export default {
  components: {
    CartProduct,
    SfHeading,
    SfButton,
    SfProperty,
    SfPrice,
  },
  data() {
    return {
      currentPaymentType: null,
      paymentComponentAsync: null,
    };
  },
  validations() {
    let validations = {};

    const personalDetails = {
      fullName: {
        required,
        minLength: minLength(2),
      },
    };

    if (this.personalDetailsConfig.email === "required") {
      personalDetails.email = {
        required,
        email,
      };
    } else if (this.personalDetailsConfig.email === "optional") {
      personalDetails.email = {
        email,
      };
    }

    if (this.personalDetailsConfig.phone === "required") {
      personalDetails.phone = {
        required,
        complex(value) {
          return (
            this.personalDetails.phone && this.personalDetails.phone.number
          );
        },
      };
    }

    if (this.deliveryMode === "ship") {
      if (this.isLoggedIn && this.useSavedAddress && this.selectedAddressId) {
        validations = { personalDetails };
      } else {
        validations = {
          personalDetails,
          shippingDetails: {
            fullName: {
              required,
              minLength: minLength(2),
            },
            streetName: {
              required,
            },
            apartment: {
              required,
            },
            city: {
              required,
            },
            state: {
              required,
            },
            zipCode: {
              required,
            },
            country: {
              required,
            },
          },
        };
      }
    } else if (this.deliveryMode === "pickup") {
      validations = { personalDetails };
    } else if (this.deliveryMode === "appointment") {
      validations = {};
    } else if (this.deliveryMode === "dine") {
      validations = {
        personalDetails: {
          fullName: personalDetails.fullName,
          phone: personalDetails.phone || {},
        },
        selectedDineInValue: {
          value: {
            complex(value) {
              return !!this.selectedDineInValue;
            },
          },
        },
      };
      if (this.store.dineSpaces && this.store.dineSpaces.length) {
        validations.selectedDineInSpaceId = {
          value: {
            complex() {
              return !!this.selectedDineInSpaceId;
            },
          },
        };
      }
    }
    return validations;
  },
  computed: {
    ...mapGetters({
      productsInCart: "cart/getCartItems",
      totals: "checkout/getTotals",
      isCartValid: "cart/isCartValid",
      numberOfItemsInCart: "cart/getNumberOfItems",
      store: "store",
      storeId: "storeId",
      deliveryMode: "checkout/getDeliveryMode",
      isLoggedIn: "user/isLoggedIn",
      phoneNumber: "user/phoneNumber",
      email: "user/email",
      fullName: "user/displayName",
      paymentsEnabled: "paymentsEnabled",
      paymentProviders: "order/paymentProviders",
      addresses: "user/addresses",
      personalDetails: "checkout/getPersonalDetails",
      shippingDetails: "checkout/getShippingDetails",
      dineInDetails: "checkout/getDineInDetails",
      shippingSlotId: "checkout/getShippingSlotId",
      useSavedAddress: "checkout/useSavedAddress",
      selectedAddressId: "checkout/selectedAddressId",
      personalDetailsConfig: "personalDetailsConfig",
    }),
    isAppointment() {
      return (
        this.store.appointmentConfig && this.store.appointmentConfig.enabled
      );
    },
    selectedDineInSpaceId() {
      return this.dineInDetails ? this.dineInDetails.spaceId : null;
    },
    selectedDineInValue() {
      return this.dineInDetails ? this.dineInDetails.value : null;
    },
    dineInEnabled() {
      return this.store.dineInConfig && this.store.dineInConfig.dineInEnabled;
    },
    defaultCountry() {
      let country = "";
      if (
        this.store.orderConfig.enabledCountries &&
        this.store.orderConfig.enabledCountries.length
      ) {
        country = CountryCodes[this.store.orderConfig.enabledCountries[0]];
      }
      return country;
    },
    deliverySlots() {
      if (
        !this.store ||
        !this.store.orderConfig ||
        !this.store.orderConfig.deliverySlots
      ) {
        return [];
      }
      return this.store.orderConfig.deliverySlots;
    },
    isFormValid() {
      this.$v.$touch();
      let isValid = true;
      if (this.$v.personalDetails && this.$v.personalDetails.$invalid) {
        console.log("Invalid personal details");
        isValid = false;
      }

      if (
        this.deliveryMode === "ship" &&
        !this.selectedAddressId &&
        this.$v.shippingDetails.$invalid
      ) {
        console.log("Invalid shipping details");
        isValid = false;
      }

      if (
        this.deliveryMode === "dine" &&
        this.$v.selectedDineInValue.$invalid &&
        this.$v.selectedDineInSpaceId &&
        this.$v.selectedDineInSpaceId.$invalid
      ) {
        console.log("Invalid dine details");
        isValid = false;
      }

      if (!this.isCartValid) {
        console.log("Invalid cart");
        isValid = false;
      }
      return isValid;
    },
    showStockErrors() {
      return !this.store.orderConfig.allowOrderOnNoStock;
    },
    paymentComponent() {
      if (this.currentPaymentType === "upi-manual") {
        return {
          component: upiManualClient(),
          loading: ALoadingSpinner,
          error: ALoadingError,
          timeout: 3000,
        };
      } else if (this.currentPaymentType === "payu") {
        return {
          component: payuClient(),
          loading: ALoadingSpinner,
          error: ALoadingError,
          timeout: 3000,
        };
      }
      return null;
    },
  },
  watch: {
    currentPaymentType(val) {
      console.log(val);
      if (val === "upi-manual") {
        this.paymentComponentAsync = {
          component: upiManualClient(),
          loading: ALoadingSpinner,
          error: ALoadingError,
          timeout: 3000,
        };
      } else if (val === "payu") {
        this.paymentComponentAsync = {
          component: payuClient(),
          loading: ALoadingSpinner,
          error: ALoadingError,
          timeout: 3000,
        };
      }
    },
    isAppointment: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.$store.commit("checkout/setDeliveryMode", "appointment");
        }
      },
    },
  },
  methods: {
    ...mapActions({
      notify: "notification/spawnNotification",
    }),
    toCatalog() {
      this.$router.push({ name: "catalog" });
    },
    removeHandler(product) {
      this.$store.dispatch("cart/removeItem", { product: product });
    },
    changeQuantity(product, newQuantity) {
      this.$store.dispatch("cart/updateQuantity", {
        product: product,
        qty: newQuantity,
      });
    },
    proceedToAppointment() {
      this.$router.push({
        name: "appointment",
      });
    },
    prepareOrder() {
      let order = {};
      let customer = this.personalDetails;
      order.personalDetails = {
        fullName: customer.fullName,
        email: customer.email || "",
        phone: customer.phone ? customer.phone.number : "",
        phoneDialCode: customer.phone ? customer.phone.dialCode : "",
      };

      if (
        this.store.orderConfig.deliveryEnabled &&
        this.deliveryMode === "ship"
      ) {
        if (this.selectedAddressId) {
          const selectedAddress = this.addresses.find(
            (x) => x.id === this.selectedAddressId
          );
          selectedAddress.country = CountryCodes[selectedAddress.countryCode];
          order.shippingDetails = selectedAddress;
        } else {
          order.shippingDetails = this.shippingDetails;
        }
      }
      if (
        this.store.orderConfig.pickupEnabled &&
        this.deliveryMode === "pickup"
      ) {
        order.pickupDetails = this.pickupDetails;
      }
      if (
        this.store.dineInConfig &&
        this.store.dineInConfig.dineInEnabled &&
        this.deliveryMode === "dine"
      ) {
        order.dineInDetails = {
          spaceId: this.selectedDineInSpaceId,
          value: this.selectedDineInValue,
        };
      }
      order.type = this.deliveryMode;
      order.totals = this.totals.map((x) => ({
        code: x.code,
        amount: x.isFree ? 0 : x.value,
        isPercentage: x.isPercentage,
        percentAmount: x.percentAmount,
      }));
      order.cart = [];
      for (let product of this.productsInCart) {
        let cartItem = { ...product };
        delete cartItem.category;
        order.cart.push(cartItem);
      }
      if (this.isLoggedIn) {
        order.userInfo = {
          email: this.email,
          phoneNumber: this.phoneNumber,
          fullName: this.fullName,
        };
      }
      if (this.shippingSlotId) {
        order.deliverySlot = this.deliverySlots.find(
          (x) => x.id === this.shippingSlotId
        );
      }
      return { sfId: this.storeId, order, deviceId: getDeviceId() };
    },
    async placeOrder() {
      this.$store.commit("ui/setLoader", true);
      if (this.email) {
        analytics.setUserEmail(this.email);
      } else if (this.personalDetails.email) {
        analytics.setUserEmail(this.personalDetails.email);
      }

      let order = this.prepareOrder();
      if (order) {
        try {
          const orderStatus = await this.$store.dispatch(
            "order/createOrder",
            order
          );
          this.$store.commit("checkout/setOrderId", orderStatus.order.bill);
          let successNotfication = notifications.createNotification({
            type: "success",
            message: `Order Placed. Order number: ${orderStatus.order.bill.toUpperCase()}. Please note this number for reference.`,
          });
          this.$store.dispatch(
            "notification/spawnNotification",
            successNotfication
          );
          analytics.logOrderEvent(this.storeId, orderStatus.order, "success");
          this.$emit("order-completed", {
            order: orderStatus.order,
            providerConfig: null,
          });
        } catch (error) {
          this.$store.dispatch(
            "notification/spawnNotification",
            notifications.createNotification({
              type: "danger",
              message:
                "Could not place your order. Please try again or contact store directly.",
            })
          );
          analytics.logOrderEvent(this.storeId, order, "failure");
        }
      }
      this.$store.commit("ui/setLoader", false);
    },
    async initiatePayment(provider) {
      this.$store.commit("ui/setLoader", true);
      analytics.setUserEmail(this.personalDetails.email);
      let order = this.prepareOrder();
      if (order) {
        try {
          const orderStatus = await this.$store.dispatch(
            `payment/${provider.providerName}/launch`,
            {
              order,
              config: provider,
            }
          );
          if (orderStatus.status === "success") {
            let successNotification = notifications.createNotification({
              type: "success",
              message: `Order Placed. Please note the displayed number for reference.`,
            });
            this.notify(successNotification);
            this.$store.commit("checkout/setOrderId", orderStatus.order.bill);
            analytics.logOrderEvent(this.storeId, order, "success");
            this.$emit("order-completed", {
              order: orderStatus.order,
              providerConfig: provider,
            });
          } else {
            let errorNotification = notifications.createNotification({
              type: "danger",
              message:
                orderStatus.error ||
                "Could not place your order. Please try again or contact store directly.",
            });
            this.notify(errorNotification);
            analytics.logOrderEvent(this.storeId, order, "failure");
          }
          this.$store.commit("ui/setLoader", false);
        } catch (error) {
          console.error(error);
          this.notify(
            notifications.createNotification({
              type: "danger",
              message:
                "Could not place your order. Please try again or contact store directly.",
            })
          );
          this.$store.commit("ui/setLoader", false);
          analytics.logOrderEvent(this.storeId, order, "failure");
        }
      }
    },
    showMaxStockNotification() {
      this.$store.dispatch(
        "notification/spawnNotification",
        notifications.createNotification({
          type: "danger",
          message: "Stock not available",
        })
      );
    },
    getSpaceInputLabel() {
      if (this.store.dineSpaces && this.selectedDineInSpaceId) {
        let space = this.store.dineSpaces.find(
          (x) => x.uid === this.selectedDineInSpaceId
        );
        return space && space.inputLabel ? space.inputLabel : "Table Number:";
      }
      return "Table Number:";
    },
    onDineInValueInput(e) {
      this.$store.commit("checkout/setDineInValue", e);
    },
    proceedToPayment(e) {
      if (this.isFormValid) {
        this.$nextTick(function () {
          const paymentSection = document.querySelector(".review-section");
          this.$smoothScroll({
            scrollTo: paymentSection,
          });
        });
      } else {
        this.$v.$touch();
        this.$nextTick(function () {
          this.focusToFirstError();
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          notifications.createNotification({
            type: "danger",
            message: "Please enter the required details",
          })
        );
      }
    },
    focusToFirstError(component = this) {
      if (component.status) {
        component.$el.focus();
        return true;
      }

      let focused = false;

      component.$children.some((childComponent) => {
        focused = this.focusToFirstError(childComponent);
        return focused;
      });

      return focused;
    },
  },
};
</script>
<style lang="scss" scoped>
.checkout-review {
  margin: var(--spacer-base) 0;

  .actions {
    margin-top: var(--spacer-lg);

    .sf-button {
      margin-bottom: 0.75rem;
      border-radius: 0.375rem;
    }
  }

  .summary__content {
    --price-font-weight: var(--font-normal);
    --property-value-font-size: var(--font-normal);

    .sf-property {
      padding: var(--spacer-xs) 0;
    }

    .grand_total {
      --property-name-font-size: var(--font-base);
      --property-name-font-weight: var(--font-bold);
      --property-name-font-color: var(--c-black);
      --price-font-size: var(--font-lg);
      --price-font-weight: var(--font-bold);
    }
  }
}
</style>
