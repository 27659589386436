<template>
  <div class="bg order-success">
    <enable-notifications-prompt
      trigger-point="order-success"
      :force-show="true"
    />
    <div class="center">
      <span class="center__success">
        <font-awesome-icon
          :icon="['fas', 'check']"
          size="4x"
          style="margin-top: 15px"
        />
      </span>

      <h1 class="card__msg">{{ successMessage }}</h1>

      <div class="card__body">
        <div class="card__recipient-info">
          <p class="card__order-number">
            Order <b>#{{ orderId.toUpperCase() }}</b>
          </p>
          <p>Please note the above number for reference</p>
        </div>
      </div>
      <SfButton
        class="sf-button--full-width color-primary cart-action"
        @click.native="startShopping"
      >
        {{ $t("Back to shopping") }}
      </SfButton>
    </div>
  </div>
</template>

<script>
import { SfButton } from "@lib/components";
import EnableNotificationsPrompt from "@theme/components/molecules/enable-notifications-prompt";

export default {
  components: {
    SfButton,
    EnableNotificationsPrompt,
  },
  data() {
    return {
      name: "name mine",
      email: "test@test.com",
    };
  },
  props: {
    successMessage: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  methods: {
    startShopping() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss">
@use "~@lib/styles/helpers/breakpoints" as *;

.order-success {
  .bg {
    background-color: var(--c-primary);
    overflow: hidden;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px;
    font-family: "Raleway sans-serif !important";
    color: white;
    height: 100vh;
    width: 100%;
  }

  .card__msg {
    font-family: "Raleway", "sans-serif";
  }

  .cart-action {
    margin-top: 3rem;
  }

  .center {
    max-width: 440px;
    margin: 0 auto;
    margin-top: 80px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 80px 30px 25px 30px;
    text-align: center;
    position: relative;

    &__success {
      display: block;
      margin: 0 auto;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-color: #60c878;
      border: 5px solid white;
    }

    &__success i {
      color: white;
      line-height: 100px;
      font-size: 45px;
    }

    &__msg {
      text-transform: uppercase;
      color: #55585b;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    &__submsg {
      color: #959a9e;
      font-size: 16px;
      font-weight: 400;
      margin-top: 0px;
    }

    &__body {
      background-color: #f8f6f6;
      border-radius: 4px;
      width: 100%;
      margin-top: 30px;
      float: left;
      box-sizing: border-box;
      padding: 30px;
    }

    &__avatar {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 7px;
    }

    &__recipient-info {
      display: inline-block;
    }

    &__recipient {
      color: #232528;
      text-align: left;
      margin-bottom: 5px;
      font-weight: 600;
    }

    &__email {
      color: #838890;
      text-align: left;
      margin-top: 0px;
    }

    &__price {
      color: #232528;
      font-size: 70px;
      margin-top: 25px;
      margin-bottom: 30px;

      span {
        font-size: 60%;
      }
    }

    &__method {
      color: #d3cece;
      text-transform: uppercase;
      text-align: left;
      font-size: 11px;
      margin-bottom: 5px;
    }

    &__payment {
      background-color: white;
      border-radius: 4px;
      width: 100%;
      height: 100px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__credit-card {
      width: 50px;
      display: inline-block;
      margin-right: 15px;
    }

    &__card-details {
      display: inline-block;
      text-align: left;
    }

    &__card-type {
      text-transform: uppercase;
      color: #232528;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 3px;
    }

    &__card-number {
      color: #838890;
      font-size: 12px;
      margin-top: 0px;
    }

    &__tags {
      clear: both;
      padding-top: 15px;
    }

    &__tag {
      text-transform: uppercase;
      background-color: #f8f6f6;
      box-sizing: border-box;
      padding: 3px 5px;
      border-radius: 3px;
      font-size: 10px;
      color: #d3cece;
    }
  }
}
</style>
