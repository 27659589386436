<template>
  <div class="order-list">
    <SfLoader v-if="loading" :loading="loading" />
    <div v-else-if="ordersError" class="error text-danger">
      {{ ordersError }}
    </div>
    <div v-else>
      <div v-if="orders.length === 0" class="no-orders">
        <p class="no-orders__title">
          {{ $t("You haven't placed any orders yet") }}
        </p>
        <SfButton class="no-orders__button" @click.native="startShopping">
          {{ $t("Start shopping") }}
        </SfButton>
      </div>
      <div v-else>
        <div v-for="order in ordersHistory" :key="order.id">
          <div class="order-card" @click="$emit('select-order', order.id)">
            <div class="card-title">
              <div class="bill-number">
                <b>{{ order.bill.toUpperCase() }}</b>
              </div>
              <div :class="'status status-' + getStatus(order)">
                {{ getStatus(order) | capitalize }}
              </div>
            </div>
            <div class="card-subtitle">
              <div class="_3OqkS">
                <span class="date">{{
                  new Date(order.cAt._seconds * 1000) | date
                }}</span>
              </div>
              <div>
                <span class="amount">
                  {{ getTotalFromOrder(order) | price }}
                </span>
                <span class="payment-mode"> by {{ order.paymentMode }} </span>
              </div>
              <div v-if="order.cancellationRequested">
                <strong>Cancellation Requested</strong>
              </div>
            </div>
            <div class="dotted-line"></div>
            <div class="card-content">
              <div>{{ getProducts(order) }}</div>
            </div>
            <div class="card-actions">
              <SfButton
                class="sf-button--outline order-action"
                @click.prevent="$emit('select-order', order.id)"
                >View Order</SfButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SfButton, SfLoader } from "@lib/components";
import { ModalList } from "@theme/store/ui/modals";
import onBottomScroll from "@lib/mixins/onBottomScroll";
import { mapGetters } from "vuex";

const getTotalFromOrder = (order) => {
  for (const total of order.order.totals) {
    if (total.code === "grand_total") {
      return total.amount;
    }
  }
};

export default {
  name: "OrderList",
  mixins: [onBottomScroll],
  components: {
    SfButton,
    SfLoader,
  },
  data() {
    return {
      tableHeaders: [
        "Order ID",
        "Order date",
        "Payment method",
        "Amount",
        "Status",
      ],
      activeOrder: null,
      pagination: {
        perPage: 10,
        current: 1,
        enabled: false,
      },
      lazyLoadOrdersOnScroll: true,
    };
  },
  computed: {
    ...mapGetters("user", ["getOrdersHistory", "ordersLoading", "ordersError"]),
    loading() {
      return this.ordersLoading;
    },
    isHistoryEmpty() {
      return this.getOrdersHistory.length < 1;
    },
    ordersHistory() {
      let items = this.getOrdersHistory;
      if (this.lazyLoadOrdersOnScroll) {
        items = items.slice(
          0,
          (this.pagination.perPage + 1) * this.pagination.current
        );
      }
      return items;
    },
    orders() {
      let orders = [];
      this.ordersHistory.forEach((item) => {
        orders.push({
          order_id: item.bill.toUpperCase(),
          order_date: this.$options.filters.date(
            new Date(item.cAt._seconds * 1000)
          ),
          payment_method: item.paymentMode || "None",
          amount: this.$options.filters.price(getTotalFromOrder(item)),
          status: this.$options.filters.capitalize(item.status || "Placed"),
        });
      });
      return orders;
    },
  },
  methods: {
    downloadAll() {
      this.$store.dispatch("ui/openModal", {
        name: ModalList.FeatureNotImplemented,
      });
    },
    setActiveOrder(order) {
      this.activeOrder = order
        ? this.ordersHistory.find(
            (item) => item.bill.toUpperCase() === order.order_id
          )
        : null;
    },
    onBottomScroll() {
      ++this.pagination.current;
    },
    startShopping() {
      this.$router.push({ name: "home" });
    },
    getProducts(order) {
      const messages = [];
      for (let product of order.order.cart) {
        let itemRow = product.title + " ";
        if (itemRow.variantName) {
          itemRow += `- ${product.variantName}`;
        }
        itemRow = `${itemRow} x ${product.qty}`;
        messages.push(itemRow);
      }
      return messages.join(", ");
    },
    getTotalFromOrder(order) {
      for (const total of order.order.totals) {
        if (total.code === "grand_total") {
          return total.amount;
        }
      }
    },
    getStatus(order) {
      if (!order.status) return "placed";
      if (order.status === "rejected") return "canceled";
      return order.status;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "~@lib/styles/helpers/breakpoints" as *;

.sf-loader {
  min-height: 100px;
}

.no-orders {
  &__title {
    margin: var(--spacer-base) 0;
  }
  &__button {
    --button-width: 100%;
    margin: var(--spacer-xl) 0;
    @include for-desktop {
      --button-width: 17.375rem;
    }
  }
}
.orders {
  @include for-desktop {
    &__element {
      &--right {
        text-align: right;
      }
    }
  }
}

.order-card {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  font-family: var(--font-family-primary);
  font-weight: 300;

  .card-title {
    display: flex;
    justify-content: space-between;
    line-height: 1.6rem;
    font-size: var(--font-sm);
  }

  .dotted-line {
    background-image: linear-gradient(
      to right,
      black 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    height: 1px;
    margin: 0.5rem 0;
  }

  .card-subtitle {
    font-size: var(--font-xs);
    line-height: 1.6rem;
  }

  .card-content {
    font-size: var(--font-xs);
    line-height: 1.6rem;
  }

  .card-actions {
    margin-top: 0.66rem;
  }
}

.order-action {
  padding: 0.66rem 1.5rem;
  --button-font-size: var(--font-xs);
  --button-color: var(--c-primary);
  border-radius: 0.375rem;
}

.message {
  margin: 0 0 var(--spacer-xl) 0;
  color: var(--c-dark-variant);
}
a {
  color: var(--c-primary);
  font-weight: var(--font-medium);
  text-decoration: none;
  &:hover {
    color: var(--c-text);
  }
}

.status {
  color: white;
  padding: 0 0.5rem;
  border-radius: 5px;
  font-weight: 400;

  &-placed {
    background: var(--c-warning);
  }

  &-confirmed,
  &-ready,
  &-shipped,
  &-out_for_delivery,
  &-fulfilled {
    background: var(--c-success);
  }

  &-canceled,
  &-rejected {
    background: var(--c-secondary);
  }
}
</style>
