<template>
  <div id="my-account">
    <div
      :class="{ 'account-pages--is-active': activePage }"
      class="account-pages"
    >
      <div class="page-links">
        <PersonalDetailsBar @on-edit-click="changePage('personal-details')" />
        <SfDivider style="margin-bottom: 1rem; --divider-border-color: black" />
        <SfList class="page-link__list">
          <SfListItem class="page-link__list-item">
            <SfMenuItem
              :class="{
                'sf-menu-item--is-active': 'manage-address' === activePage,
              }"
              label="Manage Address"
              class="page-link__menu"
              @click="changePage('manage-address')"
            >
            </SfMenuItem>
          </SfListItem>
        </SfList>
        <div class="orders-section">
          <div class="section-title full-width">YOUR ORDERS</div>
          <OrderList style="margin-top: 0.5rem" @select-order="viewOrder" />
        </div>
        <div class="logout-section">
          <div class="section-title full-width">LOGOUT</div>
          <SfList class="page-link__list">
            <SfListItem class="page-link__list-item">
              <SfMenuItem
                label="Logout"
                class="page-link__menu"
                @click="changePage('logout')"
              >
              </SfMenuItem>
            </SfListItem>
          </SfList>
        </div>
      </div>
      <div class="page-contents">
        <SfBar
          v-if="activePage && activePage !== 'view-order'"
          :back="true"
          :title="activePage"
          class="mobile-only"
          @click:back="changePage()"
        />
        <div v-if="activePage === 'personal-details'" class="page-link__page">
          <OMyAccountProfile />
        </div>
        <div v-if="activePage === 'manage-address'" class="page-link__page">
          <MyAccountShippingDetails />
        </div>
        <div v-if="activePage === 'view-order'" class="page-link__page">
          <ViewOrder @on-back="changePage()" :order-id="activeOrderId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalDetailsBar from "@theme/components/account/molecules/personal-details-bar";
import OMyAccountProfile from "@theme/components/organisms/o-my-account-profile";
import MyAccountShippingDetails from "@theme/components/organisms/my-account-shipping-details";
import OMyAccountOrdersHistory from "@theme/components/organisms/o-my-account-orders-history";
import { SfDivider, SfList, SfMenuItem, SfIcon, SfBar } from "@lib/components";
// import OMyAccountPlaceholder from "@theme/components/organisms/o-my-account-placeholder";
import ViewOrder from "@theme/components/organisms/view-order";
import OrderList from "@theme/components/account/organisms/order-list";
import { SfContentPages } from "@lib/components";
import i18n from "@lib/i18n";
import { Logger } from "@lib/utility/logger";
import { mapActions } from "vuex";

export default {
  components: {
    PersonalDetailsBar,
    OMyAccountProfile,
    MyAccountShippingDetails,
    SfDivider,
    SfList,
    SfMenuItem,
    SfBar,
    ViewOrder,
    OrderList,
  },
  props: {
    activeBlock: {
      type: String,
      default: "MyProfile",
    },
  },
  data() {
    let activePage = this.$t("My profile");
    if (this.$route.params && this.$route.params.hasOwnProperty("activePage")) {
      var mq = window.matchMedia("(max-width: 1023px)");
      if (mq.matches || this.$route.params.activePage) {
        activePage = this.$route.params.activePage;
      }
    }
    return {
      navigation: [],
      returnEditMode: false,
      activePage: null,
    };
  },
  beforeMount() {
    this.$bus.$on("myAccount-before-updateUser", this.onBeforeUpdateUser);
    this.$bus.$on(
      "myAccount-before-changePassword",
      this.onBeforeChangePassword
    );
  },
  async mounted() {
    const self = this;
    setTimeout(() => {
      if (!self.$store.getters["user/isLoggedIn"]) {
        localStorage.setItem("redirect", self.$route.path);
        self.$router.push({
          name: "home",
        });
      }
    }, 3000);
  },
  destroyed() {
    this.$bus.$off("myAccount-before-updateUser", this.onBeforeUpdateUser);
    this.$bus.$off(
      "myAccount-before-changePassword",
      this.onBeforeChangePassword
    );
  },
  methods: {
    ...mapActions({
      logoutUser: "user/logout",
    }),
    changeActivePage(title) {
      if (title === "Log out") {
        this.logout();
        return;
      }
      this.activePage = title;
    },
    changePage(name) {
      if (name === "logout") {
        this.logout();
        return;
      }
      this.activePage = name;
    },
    viewOrder(orderId) {
      this.activePage = "view-order";
      this.activeOrderId = orderId;
    },
    async logout() {
      this.logoutUser({});
      this.$router.push({ name: "home" });
      await this.$store.dispatch(
        "notification/spawnNotification",
        {
          type: "success",
          message: i18n.t("You're logged out"),
          action1: { label: i18n.t("OK") },
        },
        { root: true }
      );
    },
    onBeforeChangePassword(passwordData) {
      this.$store.dispatch("user/changePassword", passwordData);
    },
    onBeforeUpdateUser(updatedData) {
      if (updatedData) {
        try {
          this.$store.dispatch("user/update", { customer: updatedData });
        } catch (err) {
          this.$bus.$emit(
            "myAccount-before-remainInEditMode",
            this.$props.activeBlock
          );
          Logger.error(err)();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "~@lib/styles/helpers/breakpoints" as *;

#my-account {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  @include for-desktop {
    max-width: 1272px;
    margin: 0 auto;
  }
}

.account-pages {
  transition: transform 150ms ease-in-out;
  width: 100%;
  display: flex;
  flex: 1;
  max-width: 976px;

  &--is-active {
    transform: translate3d(-100%, 0, 0);
  }
}

.page-links {
  padding: 0 var(--spacer-sm);
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100vh - 3.75rem);
  flex: var(--content-pages-sidebar-flex, 0 0 100%);
  background: var(--content-pages-sidebar-background);

  .full-width {
    margin-left: calc(-1 * var(--spacer-sm));
    margin-right: calc(-1 * var(--spacer-sm));
    padding-left: var(--spacer-sm);
    padding-right: var(--spacer-sm);
  }
}

.section-title {
  padding-top: var(--spacer-sm);
  padding-bottom: var(--spacer-xs);
  background: rgba(0, 0, 0, 0.05);
  font-size: 0.75rem;
}

.page-contents {
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100vh - 3.75rem);
  flex: var(--content-pages-content-flex, 0 0 100%);
  padding: var(--content-pages-content-padding);
}

.page-link__list-item {
  --list-item-padding: var(--spacer-sm) 0;
  --list-item-border-width: 0 0 1px 0;
}

.my-account {
  --content-pages-height: auto;
  ::v-deep {
    .sf-content-pages__content,
    .sf-content-pages__sidebar {
      height: min-content;
    }
  }
  @include for-mobile {
    --content-pages-sidebar-category-title-font-weight: var(--font-normal);
    --content-pages-sidebar-category-title-margin: var(--spacer-sm)
      var(--spacer-sm) var(--spacer-sm) var(--spacer-base);
  }
  @include for-desktop {
    --content-pages-sidebar-category-title-margin: var(--spacer-xl) 0 0 0;
  }
}
.breadcrumbs {
  padding: var(--spacer-base) 0;
}
</style>
