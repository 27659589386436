<template>
  <div class="checkout-details">
    <div class="checkout-details__wrapper">
      <div class="personal-details__wrapper card">
        <SfHeading
          title="Personal details"
          :level="2"
          class="sf-heading--left sf-heading--no-underline title"
        />
        <div class="form checkout__form">
          <SfInput
            v-model.trim="personalDetails.fullName"
            :value="personalDetails.fullName"
            label="Full name"
            name="fullName"
            class="form__element form__element--half"
            :required="true"
            :valid="!$v.personalDetails.fullName.$error"
            :error-message="
              !$v.personalDetails.fullName.required
                ? $t('Field is required')
                : $t('Name must have at least 2 letters.')
            "
            @blur="$v.personalDetails.fullName.$touch()"
          />
          <SfInput
            v-if="
              deliveryMode != 'dine' && personalDetailsConfig.email !== 'skip'
            "
            v-model.trim="personalDetails.email"
            :value="personalDetails.email"
            label="Your email"
            name="email"
            class="form__element"
            :required="personalDetailsConfig.email === 'required'"
            :valid="!$v.personalDetails.email.$error"
            :error-message="
              $v.personalDetails.email.required === false
                ? $t('Field is required')
                : $t('Please provide valid e-mail address.')
            "
            @blur="$v.personalDetails.email.$touch()"
          />
          <PhoneInput
            v-if="personalDetailsConfig.phone !== 'skip'"
            v-model="personalDetails.phone"
            :value="personalDetails.phone"
            label="Phone number"
            name="phone"
            class="form__element"
            :required="personalDetailsConfig.phone === 'required'"
            :valid="!$v.personalDetails.phone.$error"
            :error-message="
              $v.personalDetails.phone.required === false
                ? $t('Field is required')
                : 'Please provide valid phone number.'
            "
            @blur="$v.personalDetails.phone.$touch()"
            @validate="isPhoneValid = $event"
          />
        </div>
      </div>
      <div
        class="delivery-mode-details__wrapper card"
        v-if="deliveryMode && deliveryMode != 'dine'"
      >
        <SfHeading
          v-if="deliveryMode === 'ship'"
          title="Shipping Details"
          :level="2"
          class="sf-heading--left sf-heading--no-underline title"
        />
        <div
          v-if="deliveryMode === 'ship'"
          class="delivery-mode-details shipping-details"
        >
          <div v-if="addresses.length">
            <SfHeading
              v-if="useSavedAddress"
              title="Select from saved addresses"
              :level="3"
              class="sf-heading--left sf-heading--no-underline title"
            />
            <transition-group
              tag="div"
              name="fade"
              class="shipping-list"
              v-if="useSavedAddress"
            >
              <div
                v-for="address in addresses"
                :key="address.id"
                class="shipping__wrapper"
              >
                <div class="shipping">
                  <div class="shipping__content">
                    <p class="shipping__address">
                      <span class="shipping__client-name">
                        {{ address.fullName }}
                      </span>
                      <br />
                      {{ address.streetName }} {{ address.apartment }}
                      <br />
                      {{ address.city }}, {{ address.state }},
                      {{ address.zipCode || address.postcode }}
                      <br />
                      {{ getCountryName(address.countryCode) }}
                    </p>
                    <p class="shipping__address">
                      {{ address.phone }}
                    </p>
                  </div>
                  <div class="shipping__actions">
                    <SfButton
                      v-if="selectedAddressId != address.id"
                      @click="
                        $store.commit('checkout/selectAddressId', address.id)
                      "
                    >
                      Select this address
                    </SfButton>
                  </div>
                </div>
                <div
                  v-if="selectedAddressId === address.id"
                  class="shipping__selected"
                >
                  <font-awesome-icon icon="check" />
                </div>
              </div>
            </transition-group>
            <SfButton
              v-if="!useSavedAddress"
              class="sf-button--full-width sf-button--text color-secondary actions__button actions__button--secondary"
              style="margin-bottom: 1.5rem"
              @click="$store.commit('checkout/setUseSavedAddress', true)"
            >
              Use a saved address
            </SfButton>
            <SfButton
              v-if="useSavedAddress"
              class="sf-button--full-width sf-button--text color-secondary actions__button actions__button--secondary"
              @click="$store.commit('checkout/setUseSavedAddress', false)"
            >
              Use a different address
            </SfButton>
          </div>
          <div v-if="addresses.length"></div>
          <div
            class="form checkout__form"
            v-if="!addresses.length || !useSavedAddress"
          >
            <SfInput
              v-model="shippingDetails.fullName"
              name="fullname"
              :label="$t('Full Name')"
              required
              :valid="!$v.shippingDetails.fullName.$error"
              :error-message="
                !$v.shippingDetails.fullName.required
                  ? $t('Field is required.')
                  : $t('Name must have at least 2 letters.')
              "
              class="form__element"
            />
            <SfInput
              v-model="shippingDetails.streetName"
              :value="shippingDetails.streetName"
              label="Street name"
              name="streetName"
              class="form__element"
              :required="true"
              :valid="!$v.shippingDetails.streetName.$error"
              :error-message="$t('Field is required')"
              @blur="$v.shippingDetails.streetName.$touch()"
            />
            <SfInput
              v-model="shippingDetails.apartment"
              :value="shippingDetails.apartment"
              label="House/Apartment number"
              name="apartment"
              class="form__element"
              :required="true"
              :valid="!$v.shippingDetails.apartment.$error"
              :error-message="$t('Field is required')"
              @blur="$v.shippingDetails.apartment.$touch()"
            />
            <SfInput
              v-model="shippingDetails.city"
              :value="shippingDetails.city"
              label="City"
              name="city"
              class="form__element form__element--half"
              :required="true"
              :valid="!$v.shippingDetails.city.$error"
              :error-message="$t('Field is required')"
              @blur="$v.shippingDetails.city.$touch()"
            />
            <SfInput
              v-model="shippingDetails.state"
              :value="shippingDetails.state"
              label="State/Province"
              name="state"
              class="form__element form__element--half form__element--half-even"
              :required="true"
              :valid="!$v.shippingDetails.state.$error"
              :error-message="$t('Field is required')"
              @blur="$v.shippingDetails.state.$touch()"
            />
            <SfInput
              v-model="shippingDetails.zipCode"
              :value="shippingDetails.zipCode"
              label="Zip-code"
              name="zipCode"
              class="form__element form__element--half"
              :required="true"
              :valid="!$v.shippingDetails.zipCode.$error"
              :error-message="$t('Field is required')"
              @blur="$v.shippingDetails.zipCode.$touch()"
            />
            <SfSelect
              :selected="shippingDetails.country"
              @change="shippingDetails.country = $event"
              name="Country"
              label="Country"
              class="form__element form__element--half form__element--half-even form__select sf-select--underlined"
              :required="true"
              :valid="!$v.shippingDetails.country.$error"
              :error-message="$t('Field is required')"
              @blur="$v.shippingDetails.country.$touch()"
            >
              <SfSelectOption
                v-for="countryOption in countries"
                :key="countryOption"
                :value="countryOption"
              >
                {{ countryOption }}
              </SfSelectOption>
            </SfSelect>
            <SfInput
              v-model="shippingDetails.phone"
              name="shippingPhone"
              :label="$t('Phone number')"
              class="form__element"
            />
            <SfCheckbox
              v-if="isLoggedIn"
              v-model="saveCurrentAddress"
              name="save-current-address"
              label="Save this address for a faster checkout next time"
              class="form__element form__checkbox"
            />
          </div>
        </div>
        <div
          v-else-if="deliveryMode === 'pickup'"
          class="delivery-mode-details pickup-details"
        >
          <div class="form checkout__form">
            <Textbox
              v-model="pickupDetails.notes"
              :value="pickupDetails.notes"
              label="Pickup notes"
              name="pickupNotes"
            />
          </div>
        </div>
      </div>
      <div v-if="!isLoggedIn" class="log-in">
        <SfButton
          class="sf-button--full-width sf-button--text form__action-button form__action-button--secondary mobile-only"
          @click="login"
        >
          Or login into your account
        </SfButton>
      </div>
      <SfButton
        class="checkout-mode__next sf-button--full-width actions__button"
        @click="next"
        >Proceed
      </SfButton>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";
import { required, minLength, email } from "vuelidate/lib/validators";
import { ModalList } from "@theme/store/ui/modals";

import {
  SfHeading,
  SfButton,
  SfInput,
  SfSelect,
  Textbox,
  SfCheckbox,
} from "@lib/components";
import PhoneInput from "@lib/components/atoms/PhoneInput.vue";
import CountryCodes from "@lib/utility/countryCodes";
import config from "@config/config";

export default {
  name: "Checkout",
  components: {
    SfHeading,
    SfButton,
    SfInput,
    SfSelect,
    Textbox,
    PhoneInput,
    SfCheckbox,
  },
  data() {
    return {
      pickupDetails: {
        notes: "",
      },
      countries: Object.keys(CountryCodes)
        .map((x) => CountryCodes[x])
        .sort(),
      isPhoneValid: false,
      personalDetails: {
        fullName: "",
        email: "",
        phone: {
          number: "",
          dialCode: "",
        },
      },
      shippingDetails: {
        fullName: "",
        streetName: "",
        apartment: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        phone: "",
      },
      saveCurrentAddress: true,
    };
  },
  validations() {
    let validations = {};

    const personalDetails = {
      fullName: {
        required,
        minLength: minLength(2),
      },
    };

    if (this.personalDetailsConfig.email === "required") {
      personalDetails.email = {
        required,
        email,
      };
    } else if (this.personalDetailsConfig.email === "optional") {
      personalDetails.email = {
        email,
      };
    }

    if (this.personalDetailsConfig.phone === "required") {
      personalDetails.phone = {
        required,
        complex(value) {
          return this.isPhoneValid;
        },
      };
    } else if (this.personalDetailsConfig.phone === "optional") {
      personalDetails.phone = {
        complex(value) {
          if (!this.personalDetails.phone || !this.personalDetails.phone.number)
            return true;
          return this.isPhoneValid;
        },
      };
    }

    if (this.deliveryMode === "ship") {
      if (this.isLoggedIn && this.useSavedAddress && this.selectedAddressId) {
        validations = { personalDetails };
      } else {
        validations = {
          personalDetails,
          shippingDetails: {
            fullName: {
              required,
              minLength: minLength(2),
            },
            streetName: {
              required,
            },
            apartment: {
              required,
            },
            city: {
              required,
            },
            state: {
              required,
            },
            zipCode: {
              required,
            },
            country: {
              required,
            },
          },
        };
      }
    } else if (this.deliveryMode === "pickup") {
      validations = { personalDetails };
    } else if (this.deliveryMode === "appointment") {
      validations = {};
    } else if (this.deliveryMode === "dine") {
      validations = {
        personalDetails: {
          fullName: personalDetails.fullName,
          phone: personalDetails.phone || {},
        },
        selectedDineInValue: {
          value: {
            complex(value) {
              return !!this.selectedDineInValue;
            },
          },
        },
      };
      if (this.store.dineSpaces && this.store.dineSpaces.length) {
        validations.selectedDineInSpaceId = {
          value: {
            complex() {
              return !!this.selectedDineInSpaceId;
            },
          },
        };
      }
    }
    return validations;
  },
  computed: {
    ...mapGetters({
      isCartValid: "cart/isCartValid",
      numberOfItemsInCart: "cart/getNumberOfItems",
      store: "store",
      storeId: "storeId",
      deliveryMode: "checkout/getDeliveryMode",
      dineInDetails: "checkout/getDineInDetails",
      isLoggedIn: "user/isLoggedIn",
      phoneNumber: "user/phoneNumber",
      email: "user/email",
      fullName: "user/displayName",
      addresses: "user/addresses",
      useSavedAddress: "checkout/useSavedAddress",
      selectedAddressId: "checkout/selectedAddressId",
      savedPersonalDetails: "checkout/getPersonalDetails",
      savedShippingDetails: "checkout/getShippingDetails",
      personalDetailsConfig: "personalDetailsConfig",
      countryCode: "user/countryCode",
    }),
    dineInEnabled() {
      return this.store.dineInConfig && this.store.dineInConfig.dineInEnabled;
    },
    selectedDineInSpaceId() {
      return this.dineInDetails ? this.dineInDetails.spaceId : null;
    },
    selectedDineInValue() {
      return this.dineInDetails ? this.dineInDetails.value : null;
    },
    defaultCountry() {
      if (this.countryCode && this.countries.indexOf(this.countryCode) !== -1) {
        return this.countryCode;
      }
      let country = "";
      if (
        this.store.orderConfig.enabledCountries &&
        this.store.orderConfig.enabledCountries.length
      ) {
        country = CountryCodes[this.store.orderConfig.enabledCountries[0]];
      }
      return country;
    },
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler: function (isLoggedIn) {
        if (isLoggedIn) {
          this.personalDetails.fullName =
            this.personalDetails.fullName || this.fullName;
          this.shippingDetails.fullName =
            this.shippingDetails.fullName || this.fullName;
          this.personalDetails.email = this.personalDetails.email || this.email;
          this.personalDetails.phone.number =
            this.personalDetails.phone.number || this.phoneNumber;
        }
      },
    },
    defaultCountry: {
      immediate: true,
      handler: function (val) {
        this.shippingDetails.country = val;
      },
    },
    savedPersonalDetails: {
      immediate: true,
      handler(details) {
        if (details && details.fullName) {
          this.personalDetails = details;
        }
      },
    },
    savedShippingDetails: {
      immediate: true,
      handler(details) {
        if (details && details.fullName) {
          this.shippingDetails = details;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      notify: "notification/spawnNotification",
    }),
    ...mapActions("ui", {
      openModal: "openModal",
    }),
    async saveAddress() {
      let addressToSave = {
        id: null,
        fullName: this.shippingDetails.fullName,
        streetName: this.shippingDetails.streetName,
        apartment: this.shippingDetails.apartment,
        city: this.shippingDetails.city,
        state: this.shippingDetails.state,
        countryCode: Object.keys(CountryCodes).find(
          (key) => CountryCodes[key] === this.shippingDetails.country
        ),
        zipCode: this.shippingDetails.zipCode,
        phone: this.shippingDetails.phone || null,
      };

      await this.$store.dispatch("user/updateAddress", addressToSave);
    },
    async next() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.notify(
          notifications.createNotification({
            type: "danger",
            message: "Please enter the required details",
          })
        );
        let self = this;
        this.$nextTick(() => {
          self.focusToFirstError();
        });
        return;
      }
      this.$store.commit("checkout/setPersonalDetails", this.personalDetails);
      this.$store.commit("checkout/setShippingDetails", this.shippingDetails);
      if (
        this.isLoggedIn &&
        (!this.useSavedAddress || this.addresses.length === 0) &&
        this.saveCurrentAddress &&
        this.deliveryMode === "ship"
      ) {
        await this.saveAddress();
      }
      this.$emit("next");
    },
    login() {
      this.openModal({ name: ModalList.Auth, payload: "login" });
    },
    toCatalog() {
      this.$router.push({ name: "catalog" });
    },
    getCountryName(code) {
      return CountryCodes[code];
    },
    focusToFirstError(component = this) {
      if (component.errorMessage) {
        component.$el.querySelector("input").focus();
        return true;
      }

      let focused = false;

      component.$children.some((childComponent) => {
        focused = this.focusToFirstError(childComponent);
        return focused;
      });

      return focused;
    },
  },
  mounted() {
    if (this.countryCode && this.countries.indexOf(this.countryCode)) {
      this.shippingDetails.countryCode = this.countryCode;
    }
  },
};
</script>
<style lang="scss">
@use "~@lib/styles" as *;

.checkout-details {
  &__wrapper {
    margin: var(--spacer-base) 0;
  }

  .log-in {
    text-align: center;
    padding: 16px 0;
    margin-bottom: 1.5rem;
    &__button {
      margin: 0 auto;
    }
  }

  .shipping-details {
    margin: 1rem 0;
  }

  .summary {
    padding: 1rem 0;
  }

  .order-success {
    width: 100%;
  }

  .actions {
    padding: 0 0 1rem 0;

    .sf-button {
      margin-bottom: 0.5rem;
    }
  }

  .sf-property {
    padding: 0 0 0.5rem 0;

    &__name {
      font-weight: 600;
    }
  }

  .card {
    color: rgba(0, 0, 0, 0.87);
    padding: 0.685rem;
    --radio-container-padding: var(--spacer-xs) 0;
    margin-bottom: 1.75rem;
    background: #fff;
  }

  .zo-textbox {
    --input-label-left: 1rem;

    &__label {
      left: 1rem;
    }
  }

  .dine-in-space-selector {
    margin-top: 1rem;

    .sf-heading {
      padding-left: 15px;

      @include for-mobile {
        padding-left: 0;
      }
    }
  }

  .inline-group {
    overflow: hidden;
    padding-top: 2rem 0;

    &-item {
      cursor: pointer;
      border-radius: 50px;
      float: left;
      margin: 0.25rem;
      position: relative;
      display: block;
      padding: 0.5rem 1rem;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);

      &.active {
        color: #fff;
        background-color: var(--c-primary);
        border-color: var(--c-primary);
      }

      &.disabled {
        color: #6c757d;
        pointer-events: none;
        background-color: #fff;
      }
    }
  }

  .shipping-list {
    margin: 0 0 var(--spacer-base) 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .shipping__wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
    position: relative;

    @include for-desktop {
      flex: 0 1 50%;
    }
  }

  .shipping {
    padding: var(--spacer-base);
    border: 1px solid #ccc;
    border-radius: 0.25rem;

    @include for-desktop {
      padding: var(--spacer-base);
    }

    &__content {
      flex: 1;
      color: var(--c-text);
    }
    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include for-desktop {
        flex-direction: row;
        align-items: center;
      }
    }

    &__selected {
      position: absolute;
      right: 0;
      padding: 0.5rem;
      box-sizing: border-box;
      background: #00bb00;
      color: white;
    }

    &__button-delete {
      --button-background: var(--c-light);
      --button-color: var(--c-dark-variant);
      &:hover {
        --button-background: var(--_c-light-primary);
      }
      @include for-desktop {
        margin: 0 0 0 var(--spacer-base);
      }
    }
    &__address {
      margin: 0 0 var(--spacer-base) 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
